import React, {Component} from 'react';
import css from './style.module.css';

import Toolbar from "../../components/Toolbar"

import Content from "../../components/Content"
import Works from "../../components/Works"

import backPicture from "../../assets/images/background.jpg"
import Teach from "../../assets/images/teach.jpeg"
import SA from "../../assets/images/cyber-security-concept_53876-93472.webp"
class App extends Component {

  state = {
    showSidebar : false,
  }

  render(){
    return (
    <div className="App">
      <Toolbar 
        showSidebar={this.state.showSidebar}
        toggleSidebar={this.toggleSidebar} />
        
     <div className={css.MainContent}> 
        <img
          className={css.backPicture}
          src={backPicture}
          alt=''
          />
          
      {/* <div  className={css.Content}>
          <h1>
            ҮЙЛ АЖИЛЛАГААНЫ ҮНДСЭН ЧИГЛЭЛ
          </h1>
          <div className={css.Work}>
            <ul>
              <Works work = "Хяналтын камерын худалдаа, суурилуулалт"/>
              <Works work = "Мэдээллийн аюулгүй байдлын цогц үйлчилгээ"/>
              <Works work = "Шилэн кабель, дэд бүтцийн суурилуулалт"/>
            </ul>
            <ul>
              <Works work = "Автомашины засвар үйлчилгээ"/>
              <Works work = "Том оврын автомашины худалдаа"/>
              <Works work = "Бүх төрлийн сэлбэгийн худалдаа"/>
            </ul>
          </div>
      </div> */}

      <h1>ВЕБ ХУУДАС ЗАСВАРТАЙ !!!</h1>
      </div>

      <div>
        {/* <img
          src={Teach}
        /> */}
        {/* <img
          src={SA}
          /> */}
      </div>
      <footer className={css.Footer}>
        Бүх эрх хуулиар хамгаалагдсан. © 2022
      </footer>
    </div>
  );
}
}

export default App;
