import React from "react";

import css from "./style.module.css"

const MenuItem = (props) => {
    return(
    <div className={css.MenuItem}>
        <li>
            <a className={props.active ? css.active : null} href={props.link}>{props.children}</a>
        </li>
    </div>
    )
}

export default MenuItem