import React from "react";

import css from "./style.module.css"

const Name = () => {
    return(
        <div className={css.Name}>
            ТЕХНИКИЙН СУРГАЛТ <span>СУДАЛГААНЫ ГАЗАР</span>
        </div>
    )
}
export default Name