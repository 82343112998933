import React from "react";

import Logo from "../Logo"
import Menu from "../Menu"
import Name from "../Name"

import css from "./style.module.css"

const Toolbar = (props) => {
    return (
        <div className={css.ToolbarOut}>
            <div className={css.Toolbar}>
                <Logo />
                <Name />
            </div>
            <div className={css.Menus}>
                <Menu />
            </div>
        </div>
    )
}

export default Toolbar;