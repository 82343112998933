import React from "react";

import css from "./style.module.css"
import MenuItem from "../MenuItem";

const Menu = () => {
    return(
        <div>
            <ul className={css.Menu}>
                <MenuItem active link="/">Нүүр</MenuItem>
                <MenuItem link="/login">Бидний тухай</MenuItem>
                <MenuItem link="/login">Үйл ажиллагаа</MenuItem>
                <MenuItem link="/login">Холбоо барих</MenuItem>
            </ul>
        </div>
    )
}

export default Menu;